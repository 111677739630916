/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useCallback, useState } from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import styles from "./layout.module.css"
import FeedbackFab from "./feedbackFAB"

const Layout = ({ children, type }) => {
  return (
    <div lang="EN">
      {type === 1 ? (
        <>
          <div className={styles.layout1}>
            <Helmet>
              <title>FinSimpl</title>
            </Helmet>
            {children}
            <FeedbackFab />
          </div>
        </>
      ) : type === 2 ? (
        <>
          <div className={styles.layout2}>
            <Helmet>
              <title>FinSimpl</title>
            </Helmet>
            {children}
            <FeedbackFab />
          </div>
        </>
      ) : null}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
