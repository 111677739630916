import React, { useCallback, useState } from "react"
import styles from "./feedbackFAB.module.css"
import emailClient from "emailjs-com"
import { Auth } from "aws-amplify"
import { makeStyles } from "@material-ui/core/styles"
import { v4 as uuidv4 } from "uuid"
import {
  TextField,
  Fab,
  FormControl,
  MenuItem,
  Typography,
  InputLabel,
  Select,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { Feedback, Close } from "@material-ui/icons"

function validateEmail(email) {
  const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailReg.test(String(email).toLowerCase())
}

const FeedbackFab = () => {
  const [open, setOpen] = useState({
    mode: "",
    status: false,
  })
  const [tag, setTag] = useState("")
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")

  const onMessage = event => {
    setMessage(event.target.value)
  }

  const onTitle = event => {
    setTitle(event.target.value)
  }

  const onEmail = event => {
    console.log(event.target.value)
    setEmail(event.target.value)
  }

  const handleTagChange = event => {
    setTag(event.target.value)
    setOpen({ ...open, mode: "" })
  }

  const onModalClose = useCallback(() => {
    closeBox()
    setTitle("")
    setMessage("")
    setTag("")
    setError("")
    setSuccess(false)
  }, [open])

  const sendFeedback = useCallback(() => {
    setError("")
    setSuccess(false)
    emailClient.init(process.env.EMAILJS_CLIENT_KEY)
    const serviceID = process.env.EMAILJS_SERVICE_ID
    const templateID = process.env.EMAILJS_FEEDBACK_TEMPLATE_ID
    const contact = Auth?.user?.attributes?.email || email
    const templateParams = {
      number: uuidv4(),
      type: tag,
      user_name: Auth?.user?.attributes?.name || "general user",
      user_email: contact,
      title,
      message,
    }
    if (!validateEmail(contact)) {
      setError(
        "Email provided is not valid or empty! Please fix this and try again."
      )
      return console.log(
        "Email validation failed: " + JSON.stringify(templateParams)
      )
      //return setSubmitStatus("fail")
    }
    emailClient.send(serviceID, templateID, templateParams).then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text)
        setSuccess(true)
      },
      function (error) {
        console.log("FAILED...", error)
        setError("Something went wrong! Please try again.")
      }
    )
  }, [email, tag, title, message])

  const openBox = () => {
    //console.log(document.getElementsByClassName(styles.fBox)[0])
    //document.getElementsByClassName(styles.fBox)[0].style.visibility = "visible"
    setOpen({ ...open, status: true })
    document.getElementsByClassName(styles.feedbackIcon)[0].style.visibility =
      "hidden"
    document.getElementsByClassName(styles.closeIcon)[0].style.visibility =
      "visible"
  }

  const closeBox = () => {
    //console.log(document.getElementsByClassName(styles.fBox)[0])
    //document.getElementsByClassName(styles.fBox)[0].style.visibility = "hidden"
    setOpen({ ...open, status: false })
    document.getElementsByClassName(styles.feedbackIcon)[0].style.visibility =
      "visible"
    document.getElementsByClassName(styles.closeIcon)[0].style.visibility =
      "hidden"
  }

  const useStyles = makeStyles(theme => ({
    titleFont: {
      fontFamily: "Montserrat !important",
      fontWeight: "600",
      fontSize: "16pt",
    },
    font: {
      fontFamily: "Montserrat !important",
      fontWeight: "400",
      fontSize: "12pt",
    },
  }))

  const mstyle = useStyles()
  return (
    <>
      <Dialog
        open={open.status}
        onClose={onModalClose}
        aria-labelledby="notes-overlay"
        className={mstyle.dialog}
      >
        <DialogTitle id="notes-overlay" style={{ marginBottom: "-25px" }}>
          <Typography className={mstyle.titleFont}>Contact Us</Typography>
        </DialogTitle>

        <DialogContent>
          <DialogContentText style={{ paddingTop: "1rem" }}>
            <Typography className={mstyle.font}>
              Fill out the form below to send us feedback, ask a question or
              report an issue! We will get back to you within 3 business days.
            </Typography>
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="header"
            label="Title"
            type="text"
            fullWidth
            onChange={onTitle}
            InputProps={{ className: mstyle.font }}
          />
          <br />
          <br />
          <TextField
            id="outlined-multiline-static"
            label="Details"
            type="text"
            multiline
            fullWidth
            rows={12}
            onChange={onMessage}
            InputProps={{ className: mstyle.font }}
          />

          <div className={styles.feedbackContainer}>
            <div className={styles.dropdownBox}>
              <FormControl
                style={{ float: "left", marginRight: 10, minWidth: 120 }}
              >
                <InputLabel id="note-label">Type</InputLabel>
                <Select
                  labelId="notes-lable-select"
                  value={tag}
                  onChange={handleTagChange}
                  label="Label"
                >
                  <MenuItem value={"Question"}>Question</MenuItem>
                  <MenuItem value={"Feedback"}>Feedback</MenuItem>
                  <MenuItem value={"Error"}>Error</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.contactBox}>
              <TextField
                autoFocus
                margin="dense"
                id="header"
                label="Contact Email"
                type="text"
                className={styles.contactField}
                onChange={onEmail}
              />
            </div>
          </div>
        </DialogContent>

        <div style={{ padding: "10px 25px 0px 25px" }}>
          {success && (
            <Alert severity="success">
              <strong>Success: </strong> Succesfully sent email!
            </Alert>
          )}
          {error.length !== 0 && (
            <Alert severity="error">
              <strong>Error: </strong>
              {error}
            </Alert>
          )}
        </div>
        <div>
          <DialogActions>
            <Button onClick={onModalClose} color="primary">
              Cancel
            </Button>
            <Button onClick={sendFeedback} color="primary">
              Send
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Fab
        aria-label="feedback"
        className={styles.feedbackIcon}
        onClick={openBox}
      >
        <Feedback />
      </Fab>
      <Fab
        aria-label="feedback"
        className={styles.closeIcon}
        onClick={closeBox}
      >
        <Close />
      </Fab>
    </>
  )
}

export default FeedbackFab
